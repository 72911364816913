html, body, #app, #root {
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
  margin-right: 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-face-allura {
  font-family: "AlluraRegular" !important;
}

.font-face-glacial-indifference {
  font-family: "GlacialIndifferenceRegular"
}

.font-face-metropolis {
  font-family: "MetropolisRegular" !important
}

.font-face-lato {
  font-family: "LatoRegular" !important
}

.login-form-wrapper {
  min-height: '100vh'
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
