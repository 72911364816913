body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'GlacialIndifferenceRegular', 'LatoRegular', 'MetropolisRegular',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
.home-screen::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
  content:url(".assets/");
}
*/

@font-face {
  font-family: "AlluraRegular";
  font-display: swap;
  src: local("AlluraRegular"),
   url("./fonts/Allura/Allura-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GlacialIndifferenceRegular";
  font-display: swap;
  src: local("GlacialIndifferenceRegular"),
   url("./fonts/GlacialIndifference/GlacialIndifference-Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "LatoRegular";
  font-display: swap;
  src: local("LatoRegular"),
  url("./fonts/Lato2OFL/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MetropolisRegular";
  font-display: swap;
  src: local("MetropolisRegular"),
  url("./fonts/metropolis/Metropolis-SemiBold.otf") format("truetype");
  font-weight: normal;
}

.card {
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%; 
  height:100%;
  top:0; 
  left:0; 
  background: rgb(0,0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 5%, rgba(0,0,0,0) 70%); 
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  z-index: 1;
}

.shadow {
  color: white;
  z-index: 1;
}

.overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.top-shadow {
  position: absolute;
  width: 100%; height:100%;
  top:0; 
  left:0;
  background:rgba(0,0,0,0.4);
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  z-index: 1;
}


.top-shadow-dark {
  cursor: pointer;
  position: absolute;
  width: 100%; height:100%;
  top:0; 
  left:0;
  background:rgba(0,0,0,0.4);
  opacity: 0.8;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  z-index: 1;
}

.top-shadow-content {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-shadow:hover {
  opacity: 1;
  cursor: pointer;
}


.top-shadow-dark:hover {
  opacity: 1;
  cursor: pointer;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

.no-arrows::-webkit-inner-spin-button, 
.no-arrows::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.fill {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.border-gray {
  border: 1px solid gray
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.icon-base {
  position: relative;
}

.icon-secondary {
  position: absolute;
  top: -7px;
  right: -7px;
  z-index: 2;
  border-radius: 90px;
}

.payment-option {
  border: 1px solid black
}

.shimmer {
  mask:linear-gradient(-60deg,#000 50%,#0005,#000 70%) right/350% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2s;
}

@keyframes shimmer {
  100% {mask-position:left}
}

/*Form upload*/

.input-file-upload {
  display: none;
}

.upload-paper {
  position: relative
}

.upload-paper:hover {
  border: 1px dashed #345a7f
}

.upload-paper-drag {
  border: 1px dashed #345a7f
}

.drag-file-element {
  position: absolute;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.user-avatar {
  border-radius: 100%;
  border: 5px solid #345a7f;
  position: relative;
  width: 100px;
  height: 100px;
}

.user-avatar img {
  border-radius: 100%;
  position: relative;
  width: 100%;
  height: 100%;
}

.user-avatar::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #345a7f;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
}

.accordion:hover {
  border-radius: 45px;
  outline: 2px solid #a5c0da; 
}

.add-card:hover {
  background-color: rgba(52, 90, 127, 0.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}


.table-view {
  cursor: pointer
}

  .table-view:hover {
    color: #345a7f;
    box-shadow: 0 5px 15px rgba(52, 90, 127, .4);
    border-radius: 10px;
  }

.info-link {
  color: #087fba
}
  .info-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

.picker {
  position: relative;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.swatch {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}